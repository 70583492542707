<template>
  <div class="popularView">
    <pageHead title="排行榜"/>
    <div class="popularView_content">
      <topic @like="like($event, index)" @favorite="favorite($event, index)" @share="handleShare"
             @comment="handleComment" v-for="(item,index) in records"
             :key="item.id" :data="item" class="topicItem" @click="handleTopicClick"/>
      <no-more v-if="!params.hasNextPage" style="margin-top:14px" text="没有了"/>
    </div>
    <commentToolDialog :show-dialog.sync="showDialog" :isAnonymousComment.sync="isAnonymousComment"
                       v-model="commentContent" hide-relation hide-img
                       @publish="handlePublishComment"/>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import topic from '@/components/topicItem'
import {articleRank} from "@/api/article";
import {addThumbsup, removeThumbsup} from "@/api/thumbsup";
import {addFavorites, removeFavorites} from "@/api/favorites";
import NoMore from '@/components/uitls/NoMore'
import {addComment} from "@/api/comment";
import commentToolDialog from '@/components/commentToolDialog'

const ENTITY_TYPE_ARTICLE = 1

export default {
  components: {
    commentToolDialog,
    pageHead,
    topic,
    NoMore
  },
  data() {
    return {
      showDialog: false,
      commentParams: {},
      commentContent: "",
      isAnonymousComment: false,
      params: {},
      records: []
    }
  },
  created() {
    this.loadList();
  },
  methods: {
    /**
     * 添加评论
     * @param item
     */
    handleComment(item) {
      this.showDialog = true
      this.commentParams = item
    },
    loadList() {
      articleRank(this.params).then(res => {
        this.records = res;
      })
    },
    /**
     * 发布评论
     */
    async handlePublishComment() {
      const {commentContent: content, commentParams: {id: entityId}, isAnonymousComment} = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({message: '评论内容不能为空', type: 'danger'});
        return
      }
      const anonymous = isAnonymousComment ? 1 : 0
      await addComment({content, entityId, entityType, anonymous})
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ""
      this.records = this.records.map(item => {
        if (item.id !== entityId) {
          return item
        }
        const {relationVO} = item
        return {
          ...item,
          relationVO: {...relationVO, commentCount: relationVO.commentCount + 1}
        }
      })
    },
    /**
     * 分享事件
     */
    handleShare({id: quoteId} = {}) {
      this.$router.push({name: 'createTopic', query: {quoteId}})
    },
    handleTopicClick({id} = {}) {
      this.$router.push({name: 'topicDetail', query: {id}})
    },
    like(res, index) {
      // 取消点赞
      if (res.thumbsUpFlag) {
        removeThumbsup({
          entityId: res.id,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.records[index];
          this.records[index].thumbsUpFlag = !res.thumbsUpFlag
          let count = data.relationVO.thumbsUpCount - 1;
          if (count < 0) count = 0;
          data.relationVO.thumbsUpCount = count
        })
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.id,
          entityType: 1
        }).then(() => {
          let data = this.records[index];
          this.records[index].thumbsUpFlag = !res.thumbsUpFlag
          if (!data.relationVO) {
            data.relationVO = {}
          }
          data.relationVO.thumbsUpCount = data.relationVO.thumbsUpCount + 1
        })
      }
    },

    favorite(res, index) {
      // 取消点赞
      if (res.favoritesFlag) {
        removeFavorites([res.id]).then(() => {
          let data = this.records[index];
          this.records[index].favoritesFlag = !res.favoritesFlag
          let count = data.relationVO.favoriteCount - 1;
          if (count < 0) count = 0;
          data.relationVO.favoriteCount = count
        })
      } else {
        // 添加点赞
        addFavorites({
          articleId: res.id,
        }).then(() => {
          let data = this.records[index];
          this.records[index].favoritesFlag = !res.favoritesFlag
          if (!data.relationVO) {
            data.relationVO = {}
          }
          data.relationVO.favoriteCount = data.relationVO.favoriteCount + 1
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popularView {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .topicItem {
    margin-top: 10px;
  }

  &_content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
